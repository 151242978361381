import React, { Component } from 'react'
import minaimg from '../images/Kaltexsj.jpg';
import logo from '../images/kaltexlogo.jpeg';
import formicon from '../images/form-icon.png';
import auth from '../../auth';
import {
    setInStorage,
    getFromStorage
  } from '../util/storage';

  export class Login extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: false,
          token: '',
          signUpError: '',          
          signUpEmail: '',
          signUpPassword: '',
        };
    
       
        this.onTextboxChangeSignUpEmail = this.onTextboxChangeSignUpEmail.bind(this);
        this.onTextboxChangeSignUpPassword = this.onTextboxChangeSignUpPassword.bind(this);        
        this.onSignUp = this.onSignUp.bind(this);
      }

      onTextboxChangeSignUpEmail(event) {
        this.setState({
            signUpEmail: event.target.value,
        });
        
      }
    
      onTextboxChangeSignUpPassword(event) {
        this.setState({
          signUpPassword: event.target.value,
        });
        
      }
      componentDidMount() {
        const obj = getFromStorage('the_main_app_kal2');
        const tip = getFromStorage('tipo_kal2');
        if (obj && obj.token && tip && tip.tipo) {
          const { token } = obj;
          // Verify token
          fetch('https://api.instrutronica-iot.com/api/la/verify?token=' + token)
            .then(res => res.json())
            .then(json => {
              if (json.success) {
                auth.login();
                this.props.history.push("/mapa");
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }
    
      onSignUp = async (e) =>{
        e.preventDefault();

        // Grab state
        const {
            signUpEmail,
          signUpPassword,
        } = this.state;
        
      
         // Post request to backend
    fetch('https://api.instrutronica-iot.com/api/la/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: signUpEmail,
          password: signUpPassword,
        }),
      }).then(res => res.json())
        .then(json => {
          if (json.success) {
            setInStorage('the_main_app_kal2', { token: json.token });
            setInStorage('tipo_kal2',{tipo:json.tipo});
            auth.login();
            this.props.history.push("/mapa");
          } else {
            this.setState({
              signUpError: json.message,
              isLoading: false,
            });
          }
        });
        
      }


    render() {
        const {        
            signUpEmail,
            signUpPassword,
            signUpError,
          } = this.state;

         
        return (
            
            <div className="main-container">
            <div className="background-content container-fluid">
                <div className="page-content-div">
                    <div className="form-sec">
                        <div className="logo-img">
                            <a href="/" title="Login">
                                <img src={logo} alt="Site Logo" ></img>
                            </a>
                        </div>
                        <div className="form-content">
                            <h4>
                                <img src={formicon} alt="form-icon.png" />&nbsp;Buen día
                            </h4>
                            <p>Ingresa al sistema con tus credenciales.</p>
                        </div>
                        <div className="form-area">
                            <form  id="phpcontactform" noValidate="novalidate">
                                <div className="form-group">
                                <input value={signUpEmail}    type="text" placeholder="Usuario" autoComplete="off" required data-msg="Por favor ingrese su usuario" onChange={this.onTextboxChangeSignUpEmail} className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control"  data-msg="Por favor ingrese su contraseña" onChange={this.onTextboxChangeSignUpPassword} value={signUpPassword} placeholder="Contraseña" required/>
                                </div>
                                 {
                                  (signUpError) ? (
                                    <div className="form-group forgot-link"><p>{signUpError}</p></div>
                                  ) : (null)
                                }
                               
                                <div className="form-group">
                                <input type="button" onClick={this.onSignUp} className="form-control"  value="Iniciar Sesión"/>
                                </div>
                            </form>
                        </div>
                        <div className="user-info">
                            <div className="person-detail">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="img-content">
                    <p>Kaltex</p>
                    <p>México</p>
                </div>
            </div>
            <div className="mobile-display-section">
                <img src={minaimg} alt="responsive-bg-new.jpg" />
                <div className="mobile-img-content">
                    <p>Kaltex</p>
                    <p>México</p>
                </div>
            </div>
          </div>
                   
                    )
                }
            }
            
            export default Login