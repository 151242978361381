export function getFromStorage(key) {
    if (!key) {
      return null;
    }
    try {
      const valueStr = localStorage.getItem(key);
      if (valueStr) {
        return JSON.parse(valueStr);
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  export function setInStorage(key, obj) {
    if (!key) {
      console.error('Error: Key is missing');
    }
    try {
      localStorage.setItem(key, JSON.stringify(obj));
    } catch (err) {
      console.error(err);
    }
  }

  export function destroyStorage(key) {
    if (!key) {
      return null;
    }
    try {
    const valueStr = localStorage.getItem(key);
    const dd= JSON.parse(valueStr);
      fetch('https://api.instrutronica-iot.com/api/la/logout?token=' + dd.token)
      .then(() => {
        if (localStorage.removeItem(key)&&localStorage.removeItem('tipo_kal2')) {
          return true;
        } return null;
      });     
    } catch (err) {
      return null;
    }
  }

