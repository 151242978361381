import React, { Component } from 'react';

import Navigation from '../Navigation';
import BuscarSitio from './BuscarSitio';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { withRouter } from 'react-router-dom';
export class Sitios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lecturas: 
                
                   
                        {
                            _id: "-",
                            idSitio: "-",
                            idEquipo: "-",
                            idMedidor: "-",
                            nombre: "-",
                            tipo: "M1",
                            lat: 0,
                            long:0,
                            cantidad_equipos: "-",
                            min: 0,
                            max: 0
                            
                        },
                    
                
            
            sitioid:''
        };
    }

    recibirDatosP = (childData) =>{
        this.setState({lecturas: childData});
    }
    
    onInputChange = (e) => {       
        const todoestado=this.state.lecturas;
        if(e.target.name==='nombre'){
            todoestado.nombre=e.target.value;
            this.setState({lecturas:todoestado})
        }        
        else if(e.target.name==='cantidad_equipos'){
            todoestado.cantidad_equipos=e.target.value;
            this.setState({lecturas:todoestado})
        }
        
        else if(e.target.name==='min'){
            todoestado.min=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='max'){
            todoestado.max=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='lat'){
            todoestado.lat=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='long'){
            todoestado.long=e.target.value;
            this.setState({lecturas:todoestado})
        }
        
        else if(e.target.name==='medidor'){
            todoestado.idMedidor=e.target.value;
            this.setState({lecturas:todoestado})
        }

      
    }
    actualizarDatos = async (e) =>{
        const updatedNote = {
            nombre: this.state.lecturas.nombre,            
            lat: this.state.lecturas.lat,
            long: this.state.lecturas.long,
            cantidad_equipos: this.state.lecturas.cantidad_equipos,            
            min: this.state.lecturas.min,
            max: this.state.lecturas.max,
            idMedidor: this.state.lecturas.idMedidor
           
        };
        const res = await axios.put('https://api.instrutronica-iot.com/api/la/sitios/' + this.state.lecturas._id, updatedNote);
        //console.log(res.data);
        if(res.data==='Sitio Actualizado'){
            ToastsStore.success("sitio Actualizado");
        }else{
            ToastsStore.error("Fallo Actualización");
        }
    }
    async componentDidMount() {
        this.interval =  setInterval(async() =>{
            const tip =  JSON.parse(localStorage.getItem('tipo_kal2'));
            if(tip.tipo !=='admin'){
                this.props.history.push("/");
            }
        },1000);
    }
    render() {

        

        
        
     


        return (
            <div id="my-page">
                <div className="main-container bg-img">
                <Navigation activo="alertas"></Navigation>
                <section className="widget-sec">
                    <div className="container">                       
                        <div className="content-area">
                        <BuscarSitio recibirDatosP={this.recibirDatosP} ></BuscarSitio>
                        <div className="col-lg-12 col-12 ">
                    <form>
                    <div className="form-group ">
                        
                        
                            <label htmlFor="nombre">Nombre del Sitio Alerta</label>
                            <input type="text" name="nombre" className="form-control" onChange={this.onInputChange} value={this.state.lecturas.nombre}></input>
                            <div className="form-group">
                                <label htmlFor="idMedidor">Seleccione el medidor o medidores instalados en ese sitio.</label>
                                
                                <select onChange={this.onInputChange} value={this.state.lecturas.idMedidor} className="form-control" name="medidor" >
                                    <option value="M1">Badger Meter M5000</option>
                                    
                                </select>
                            </div>
                          
                            
                                

                                <label htmlFor="lat">Latitud:</label><input name="lat" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.lat}></input>
                                <label htmlFor="long">Longitud:</label><input name="long" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.long}></input>
                            
                           
                                
                            
                            
                            <label htmlFor="cantidad_equipos">Cantidad de Equipos:</label><input name="cantidad_equipos" onChange={this.onInputChange} className="form-control" type="text" value={this.state.lecturas.cantidad_equipos}></input>
                            
                            
                            <div className="form-group">
                                
                               
                                <h2 className="h2">Configuración de Márgenes de Lecturas del Sitio</h2>
                            </div>

                           
                           
                            <div className="form-group"  >
        
        <label htmlFor="min">Min:</label><input name="min" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.min}></input>
        <label htmlFor="max">Max:</label><input name="max" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.max}></input>
       
    </div>
                            
                            
                        
                    </div>
                    </form>
                </div>
                <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                    <button onClick={this.actualizarDatos} className="btn btn-danger">Actualizar</button>
                    <ToastsContainer store={ToastsStore}  />
                </div>
                        </div>
                        
                    </div>
                </section>
                </div>
            </div>
           
        )
    }
}

export default withRouter(Sitios)
