import React, { Component } from 'react';
import Navigation from '../Navigation';
import BuscarSitio from './BuscarSitio';
import axios from 'axios';
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { withRouter } from 'react-router-dom';
export class Equipos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lecturas: 
                
                   
                        {
                            idEquipo: "-",                            
                            ioSMSC: 0,                            
                            horaSMSC: 0,
                            RFCNoC: "-",
                            lat:"-",
                            long:"-",
                            num1:"0000000000",
                            num2:"0000000000",
                            num3:"0000000000",
                            _id: "-"
                            
                            
                        },
                    
                
            
            sitioid:''
        };
    }
    recibirDatosP = (childData) =>{
        this.setState({lecturas: childData});
    }
    onInputChange = (e) => {       
        const todoestado=this.state.lecturas;
        if(e.target.name==='ioSMSC'){
            todoestado.ioSMSC=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='horaSMSC'){
            todoestado.horaSMSC=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='RFCNoC'){
            todoestado.RFCNoC=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='lat'){
            todoestado.lat=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='long'){
            todoestado.long=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='num1'){
            todoestado.num1=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='num2'){
            todoestado.num2=e.target.value;
            this.setState({lecturas:todoestado})
        }
        else if(e.target.name==='num3'){
            todoestado.num3=e.target.value;
            this.setState({lecturas:todoestado})
        }
      
    }
    actualizarDatos = async (e) =>{
        const updatedNote = {
            ioSMSC: this.state.lecturas.ioSMSC,
            horaSMSC: this.state.lecturas.horaSMSC,
            RFCNoC: this.state.lecturas.RFCNoC,
            lat: this.state.lecturas.lat,
            long: this.state.lecturas.long,
            num1: this.state.lecturas.num1,
            num2: this.state.lecturas.num2,
            num3: this.state.lecturas.num3,
            idEquipo: this.state.lecturas.idEquipo
        };
        const res = await axios.put('https://api.instrutronica-iot.com/api/la/normac/', updatedNote);
        //console.log(res.data);
        if(res.data==='Equipo Actualizado'){
            ToastsStore.success("Equipo Actualizado");
        }else{
            ToastsStore.error("Fallo Actualización");
        }
    }
    async componentDidMount() {
        this.interval =  setInterval(async() =>{
            const tip =  JSON.parse(localStorage.getItem('tipo_kal2'));
            if(tip.tipo !=='admin'){
                this.props.history.push("/");
            }
        },1000);
    }
    render() {
        return (
            <div id="my-page">
                <div className="main-container bg-img">
                <Navigation activo="normac"></Navigation>
                <section className="widget-sec">
                    <div className="container">                       
                        <div className="content-area">
                        <BuscarSitio recibirDatosP={this.recibirDatosP} ></BuscarSitio>
                            <div className="col-lg-12 col-12 ">
                            <form>
                                <div className="form-group ">
                                    
                                    
                                       
                                        <label htmlFor="RFCNoC">RFC:</label><input name="RFCNoC" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.RFCNoC}></input>
                                        <label htmlFor="lat">Latitud:</label><input name="lat" maxLength="10" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.lat}></input>
                                        <label htmlFor="long">Longitud:</label><input name="long" maxLength="10" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.long}></input>
                                        
                                        <div className="form-group">
                                            <label htmlFor="idMedidor">Activar envíos SMS</label>                                            
                                            <select onChange={this.onInputChange} value={this.state.lecturas.ioSMSC} className="form-control" name="ioSMSC" >
                                                <option value="0">Apagado</option>
                                                <option value="1">Encendido</option>
                                            </select>
                                        </div>

                                        <label htmlFor="horaSMSC">Hora de envío SMS (de 0 a 23):</label><input name="horaSMSC" className="form-control" type="number" min="0" max="23" onChange={this.onInputChange} value={this.state.lecturas.horaSMSC}></input>
                                        <label htmlFor="num1">Número 1 (Formato +525555555555):</label><input name="num1" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.num1}></input>
                                        <label htmlFor="num2">Número 2 (Formato +525555555555):</label><input name="num2" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.num2}></input>
                                        <label htmlFor="num3">Número 3 (Formato +525555555555):</label><input name="num3" className="form-control" type="text" onChange={this.onInputChange} value={this.state.lecturas.num3}></input>
                                       
                                </div>
                                
                                </form>
                               
                            </div>
                            <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                                    <button onClick={this.actualizarDatos} className="btn btn-danger">Actualizar</button>
                                    <ToastsContainer store={ToastsStore}  />
                                </div>
                        </div>
                 </div>
                </section>
                </div>
            </div>
        )
    }
}

export default withRouter(Equipos)
