import React, { Component } from 'react'
import axios from 'axios';
export class BuscarSitio extends Component {
    
    state={
        sitios2:[],
        sitio:"",
        
    }

    async componentDidMount(){
        //pedir sitios al servidor
        const res = await axios.get('https://api.instrutronica-iot.com/api/la/sitios/kaltex2/');
        this.setState({sitios2: res.data});
    };

    valorSelect (event) {
        var sp = event.target.value.split(",");
        this.setState({sitio: sp[0]});
    }

    async onClick (){
        //console.log('http://localhost:4001/api/la/datos/' + this.state.sitio);
        const res = await axios.get('https://api.instrutronica-iot.com/api/la/datos/' + this.state.sitio);
        //console.log(res.data);
        //this.setState({ lecturas: res.data });
        this.props.recibirDatosP(res.data[0].sitio[0]);
       
    }

    render() {
        return (
            <div className="row">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <label className="input-group-text" htmlFor="inputGroupSelect01">Sitios</label>
                    </div>
                    <select className="custom-select" id="inputGroupSelect01" onChange={this.valorSelect.bind(this)}>
                        <option defaultValue>Elije un sitio...</option>
                        {
                            this.state.sitios2.map(sitio =>
                                <option key={sitio._id} multiple={true} value={[sitio.idSitio, sitio.tipo]}>{sitio.nombre}</option>
                            )
                        }                       
                    </select>
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={this.onClick.bind(this)}>Cargar Sitio</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default BuscarSitio
