import React, { Component } from 'react';
import Navigation from '../Navigation';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';



export class Lecturas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lecturas: [],
            fecha1:'',
            fecha2:'',
            sitio:'',
            nombre:''
        };
    }
    
   
    
    fecha1(event){
        this.setState({fecha1:event.target.value});
        
    };
    fecha2(event){
        this.setState({fecha2:event.target.value});
    };
    async cargarlecturas(){        
        const res = await axios.get('https://api.instrutronica-iot.com/api/la/lecturas1/fechas/' + this.state.sitio+'/'+this.state.fecha1+'/'+this.state.fecha2);
        //console.log('https://api.instrutronica-iot.com/api/la/lecturas1/fechas/' + this.state.sitio+'/'+this.state.fecha1+'/'+this.state.fecha2);
        this.setState({ lecturas: res.data});             
    };
    
   

    async componentDidMount() {
        var query = window.location.href;
        var sit = query.split("/");
        this.setState({sitio: sit[5]});
        console.log(sit);
        var res = await axios.get('https://api.instrutronica-iot.com/api/la/lecturas1/kaltex/hoy/' + sit[5]);
        this.setState({ lecturas: res.data });
        res = await axios.get('https://api.instrutronica-iot.com/api/la/sitios/' + sit[5]);
        this.setState({nombre: res.data[0].nombre});

        this.interval =  setInterval(async() =>{
            if(localStorage.getItem('the_main_app_kal2')==null){
                this.props.history.push("/");
            }
        },1000);

    }

    render() {
        let count=1;
        return (
            <div id="my-page">
            <div className="main-container bg-img">
            <Navigation activo="lecturas"/>
            <section className="widget-sec">
            <div className="container">
               <div className="row">
                    <div className="col-md-6 col-sm-12 page-title">
                        <h2>{this.state.nombre}</h2>
                    </div>
                   
                </div>
                <div className="content-area">
                <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h3>Revisa por fechas las lecturas</h3>
                                <form>
                                    <div className="row">
                                        <div className="col-md-4 mb-3">
                                        <input id="fecha1" onChange={this.fecha1.bind(this)}  type="date" className="form-control" placeholder="Fecha Inicial" aria-label="Fecha Inicial" aria-describedby="button-addon4"></input>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                        <input id="fecha2" onChange={this.fecha2.bind(this)} type="date" className="form-control" placeholder="Fecha Final" aria-label="Fecha fianl" aria-describedby="button-addon4"></input>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                        <button className="btn  btn-primary" type="button" onClick={this.cargarlecturas.bind(this)}>Cargar Lecturas</button>   
                                        <CSVLink className="btn btn-info"
                                                data={this.state.lecturas}
                                                asyncOnClick={true}
                                                filename={""+this.state.nombre+"-"+this.state.fecha2+"-reporte.csv"}                                                
                                                >
                                                Descargar CSV
                                                </CSVLink>
                                        </div>

                                    </div>
                                   

                                </form>
                            </div>

                        </div>
                        <div className="about-sec-2">
                                        <div className="row">
                                        <div className="table-responsive">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Caudal</th>
                                                            <th>Volumen</th>
                                                            <th>Fecha</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            
                                                            this.state.lecturas.map(function (lectura) {
                                                                return <tr key={lectura._id}>
                                                                    <th scope="row">{count++}</th>
                                                                    
                                                                    <td>{(lectura.caudal===null) ? 'null':(lectura.caudal.toFixed(3))}</td>
                                                                    <td>{(lectura.volumen2===null) ? 'null':(lectura.volumen2)}</td>
                                                                    <td>{(!lectura.fechalec)?(lectura.fechalectura):(lectura.fechalec)}</td>
                                                                </tr>;
                                                            })
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>

                                            
                                        </div>
                                    </div>
                </div>
            </div>
            </section>
            </div>
        </div>
        )
    }
}

export default withRouter(Lecturas)